import makeStyles from '@ui/utils/makeStyles';

const drawerWidth = 256;

export default makeStyles()((theme) => ({
  backButton: {
    textShadow: '0px 2px 5px rgba(0, 0, 0, .5)',
  },
  fab: {
    margin: theme.spacing(1),
    padding: `0 ${theme.spacing(3)} 0 ${theme.spacing(2)} !important`,
    textTransform: 'none',
  },
  fabIcon: {
    marginRight: theme.spacing(1),
    width: '15px !important',
    height: '15px !important',
  },
  hero: {
    width: '100%',
    minHeight: 250,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      minHeight: 350,
    }
  },
  heroOverlay: {
    width: '100%',
    height: '100%',
    background: 'black',
  },
  heroContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(5),
  },
  heroInfo: {
    display: 'flex',
    alignItems: 'flex-end',
    color: theme.palette.common.white,
    zIndex: 2,
    textShadow: '0px 2px 5px rgba(0, 0, 0, .5)',
  },
  heroBack: {
    color: theme.palette.common.white,
    flex: 1,
  },
  heroLabel: {
    color: theme.palette.common.white,
    textShadow: '0px 2px 5px rgba(0, 0, 0, .5)',
  },
  heroSwitchThumb: {
    color: theme.palette.common.white,
  },
  heroSwitchTrack: {
    backgroundColor: `${theme.palette.common.white} !important`,
  },
  heroButton: {
    marginRight: `${theme.spacing(1)} !important`,
    background: '#f7f7f7 !important',
    color: theme.palette.common.black,
    fill: theme.palette.common.black,
    border: 0,
    '&:last-of-type': {
      marginRight: '0 !important',
    }
  },
  mobileHorizontalScrollWrapper: {
    [theme.breakpoints.down(undefined)]: {
      marginLeft: theme.spacing(-4),
      marginRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      width: '100vw',
    },
    marginTop: theme.spacing(2),
    WebkitOverflowScrolling: 'touch',
  },
  mobileHorizontalScrollContainer: {
    display: 'flex',
    marginRight: theme.spacing(4),
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& > span:last-child': {
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'flex-end',
      marginRight: 0,
    },
  },
  removeButton: {
    position: 'absolute',
    right: -25,
    bottom: 18,
    height: 24,
    width: 24,
    [theme.breakpoints.up('sm')]: {
      right: -30,
    }
  }, 
  timelineItem: {
    borderRadius: theme.shape.borderRadius,
    width: '100%',
    height: 56,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  timelineContactsWrapper: {
    order: 1,
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(5),
    borderLeft: '1px solid #DDDDDD',
    width: '100%',
    backgroundColor: '#F7F7F7',
    [theme.breakpoints.up('md')]: {
      height: '100%',
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5)
    }
  },
  infoWrapper: {
    [theme.breakpoints.up('md')]: {
      order: 1
    }
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#f7f7f7',
    borderRight: 'none',
    paddingLeft: 24,
    paddingRight: 24,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 48,
      paddingRight: 24,
    }
  },
  drawerHeaderClose: {
    position: 'relative',
    top: -theme.spacing(-1),
    margin: '0 auto',
    display: 'flex',
    borderRadius: 0,
    fontSize: 16,
    alignItems: 'center',
  },
  drawerHeader: {
    alignItems: 'start',
    flexDirection: 'column',
    padding: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(6, 2, 4),
    }
  },
  tabs: {
    flexDirection: 'column',
    '& .MuiTabs-flexContainer': {
      gap: 4,
    },
    '& .MuiTab-root': {
      color: '#666666',
      backgroundColor: '#f7f7f7',
      padding: theme.spacing(1.5, 2),
      transition: 'background-color .2s',
      cursor: 'pointer',
      textDecoration: 'none',
      fontFamily: theme.gh_vars.circular,
      fontSize: 16,
      borderRadius: 100,
      justifyContent: 'flex-start',
      minHeight: 0,
      '&.Mui-selected': {
        backgroundColor: '#ffffff',
        color: '#000000',
      },
      '&:hover': {
        backgroundColor: '#ffffff',
        color: '#000000',
      },
      '& svg': {
        width: 24,
        height: 24,
      }
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  createHomeChannelButton: {
    stroke: theme.palette.common.black, 
    color: theme.palette.common.white,
    '& svg': {
      height: 28,
      width: 28,
    },
    minWidth: 50,
  }
}));
