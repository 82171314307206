import makeStyles from '@ui/utils/makeStyles';

const drawerWidth = 256;

export default makeStyles()((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#f7f7f7',
  },
  appBar: {
    transition: `all ${theme.transitions.duration.leavingScreen}ms ${theme.transitions.easing.sharp}`,
    backgroundColor: '#f7f7f7',
    boxShadow: 'none',
    padding: theme.spacing(0.5),
    borderBottom: 'solid 1px #e5e8eb',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0.5, 6),
      zIndex: 1300,
    }
  },
  appBarShift: {
    width: '100%',
  },
  toolbar: {
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0),
    }
  },
  toolbarClose: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    fontSize: 16,
    marginRight: 16,
    textDecoration: 'none',
    [theme.breakpoints.up('lg')]: {
      gap: 16,
      marginRight: 0,
    }
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  toolbarLogo: {
    [theme.breakpoints.up('lg')]: {
      width: 208,
    }
  },
  toolbarSearch: {
    paddingRight: theme.spacing(0.5),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(4),
      flex: 1,
    }
  },
  menuButton: {
    marginRight: theme.spacing(1),
    '& svg': {
      fill: '#000000',
    }
  },
  hide: {
    display: 'none',
  },
  searchField: {
    marginRight: 24,
    maxWidth: 400,
    marginTop: 10,
    marginBottom: 8,
    opacity: 1,
    transition: `all ${theme.transitions.duration.enteringScreen}ms ${theme.transitions.easing.easeOut}`,
    [theme.breakpoints.up('sm')]: {
      marginRight: 217,
      marginTop: 7,
    }
  },
  searchFieldHide: {
    opacity: 0,
    transition: `all ${theme.transitions.duration.leavingScreen}ms ${theme.transitions.easing.sharp}`,
  },
  searchFieldInput: {
    borderRadius: '50px',
    borderColor: '#92a0ab',
    backgroundColor: '#fff',
    fontFamily: theme.gh_vars.mabryMono,
    '& input': {
      fontSize: 13,
      padding: theme.spacing(1, 2),
    }
  },
  searchFieldIcon: {
    fill: '#666666',
    height: 18,
    width: 18,
    '& path': {
      stroke: '#666666'
    }
  },
  profileMenuRegionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      flexDirection: 'row'
    }
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#f7f7f7',
    borderRight: 'none',
    paddingLeft: 24,
    paddingRight: 24,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 48,
      paddingRight: 24,
    }
  },
  drawerHidden: {
    display: 'none',
  },
  drawerHeaderClose: {
    position: 'relative',
    top: -theme.spacing(-1),
    margin: '0 auto',
    display: 'flex',
    borderRadius: 0,
    fontSize: 16,
    alignItems: 'center',
  },
  drawerHeader: {
    alignItems: 'start',
    flexDirection: 'column',
    padding: theme.spacing(2),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(6, 2, 4),
    }
  },
  logo: {
    display: 'none',
    width: 174,
    height: 'auto',
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    }
  },
  drawerLogo: {
    display: 'flex',
    justifyContent: 'center',
  },
  drawerLogoSvg: {

  },
  navIcon: {
    width: 20,
    height: 20,
  },
  navigation: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  navigationItem: {
    display: 'flex',
    justifyContent: 'start',
    gap: 12,
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(1.5, 2),
    backgroundColor: '#f7f7f7',
    transition: 'background-color .2s',
    cursor: 'pointer',
    textDecoration: 'none',
    fontFamily: theme.gh_vars.circular,
    fontSize: 16,
    borderRadius: 100,
    color: '#666666',
    '&:hover': {
      backgroundColor: '#ffffff',
      color: '#000000',
    }
  },
  navigationItemActive: {
    backgroundColor: '#ffffff',
    color: '#000000',
  },
  navigationIcon: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      width: 24,
      height: 24,
    }
  },
  navigationLabel: {
    textAlign: 'center',
  },
  content: {
    width: '100%',
    flexGrow: 1,
    marginLeft: -drawerWidth,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: '#f7f7f7',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      marginLeft: 0,
    }
  },
  contentToolbarSpacing: {
    minHeight: 56,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      minHeight: 48,
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: 64,
    },
  }
}));
