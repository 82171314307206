import { database } from '@app/firebase';
import ProfileMenu from '@components/profile-menu/ProfileMenu';
import AppContext from '@context/AppContext';
import UserContext from '@context/UserContext';
import useDebounceEffect from '@hooks/useDebounceEffect';
import useRouter from '@hooks/useRouter';
import GuestHouseLogoFull from '@images/guesthouse-logo-full';
import Sentry from '@integrations/Sentry';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Close from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import routes from '@routes';
import Flagged from '@ui/components/flagged/Flagged';
import Protected from '@ui/components/protected/Protected';
import useBreakpoints from '@ui/hooks/useBreakpoints';
import MenuIcon from '@ui/icons/imaterial/base/Hamburger Menu.svg';
import IconSearch from '@ui/icons/imaterial/base/Search.svg';
import checkRoles from '@ui/utils/checkRoles';
import { collection, doc, updateDoc } from 'firebase/firestore';
import { some } from 'lodash';
import querystring from 'query-string';
import React, { createRef, useContext, useEffect, useMemo, useState } from 'react';
import { matchPath } from 'react-router';
import { Link, useSearchParams } from 'react-router-dom';

import useStyles from './Backend.style';

const searchPlaceholderText = (pathname: string, roles: guesthouse.Roles): string => {
  if (matchPath(routes.products.path, pathname)) {
    return checkRoles(['maker'], roles) ? 'Search your products' : 'Search products';
  }
  if (matchPath(routes.inventory.path, pathname)) {
    return checkRoles(['maker'], roles) ? 'Search your inventory' : 'Search inventory';
  }
  return 'Search';
};

const Backend: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { classes, cx, theme } = useStyles();
  const router = useRouter();
  const searchInputRef = createRef<HTMLInputElement>();
  const appContext = useContext<AppContext>(AppContext);
  const userContext = useContext<UserContext>(UserContext);
  const [searchQuery, setSearchQuery] = useState(router.query.q?.toString());
  const { md } = useBreakpoints();
  const [searchParams, setSearchParams] = useSearchParams();
  // lg isn't destructred from the hook because it causes the side appbar to flicker without the noSrr option
  const lg = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });

  const handleDrawerOpen = () => {
    appContext.setContext(state => ({ ...state, navOpen: true }));
  };

  const handleDrawerClose = () => {
    appContext.setContext(state => ({ ...state, navOpen: false }));
  };

  const INTERNAL_SEARCH_ROUTES = [routes.products.path, routes.inventory.path];
  const isOpen = lg || appContext.navOpen;
  const isSearchPage = !!matchPath(routes.search.path, router.location.pathname);


  // check if the current page is a single home page so we can show different navigation
  const isSingleHomePage = !!matchPath(routes.home.path + '/*', router.location.pathname);
  let isSingleHome = false;

  if (isSingleHomePage) {
    const isHomeslistPage = !!matchPath(routes.homes.path + '/all', router.location.pathname);

    if (!isHomeslistPage) {
      isSingleHome = true;
    }
  }

  const isInternalSearch = some(INTERNAL_SEARCH_ROUTES.map((route) => matchPath(route, router.location.pathname)));

  useDebounceEffect(() => {
    if (!isInternalSearch && !isSearchPage && searchQuery) {
      router.push({
        pathname: routes.search.path,
        search: querystring.stringify({
          q: searchQuery,
        }),
      });
    } else {
      if (searchQuery) {
        searchParams.set('q', searchQuery);
      } else {
        searchParams.delete('q');
      }
      setSearchParams(searchParams);
    }
  }, 200, [searchQuery]);

  useEffect(() => {
    if (!(isSearchPage || isInternalSearch) && searchInputRef && searchInputRef.current) {
      searchInputRef.current.value = '';
    }
  }, [isSearchPage]);

  useEffect(() => {
    appContext.setContext(state => ({ ...state, navOpen: false }));
  }, [router.location]);

  const regionClear = useMemo(() => {
    return async () => {
      await updateDoc(doc(collection(database, 'users'), userContext.user.uid), {
        activeRegion: null,
      })
        .catch(Sentry.captureException);
    };
  }, [JSON.stringify(userContext?.roles), userContext.user?.uid]);

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar
        position="fixed"
        className={cx(classes.appBar, {
          [classes.appBarShift]: isOpen,
        })}
      >
        <Toolbar className={classes.toolbar}>
          {false && (
            <Switch
              checked={appContext.skeleton}
              onChange={(e) => {
                appContext.setContext(state => ({ ...state, skeleton: e.target.checked }));
              }}
            />
          )}
          <div
            className={classes.toolbarContainer}
          >
            {!isOpen && (
            <IconButton
              color="primary"
              aria-label="open drawer"
              edge="start"
              className={cx(classes.menuButton, { [classes.hide]: isOpen })}
              size="large"
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
            )}
          
            <div className={classes.toolbarLogo}>
              {isSingleHome ? (
                <Link
                  to={routes.feed.path}
                  className={classes.toolbarClose}
                >
                  <Close />
                  Close
                </Link>
              ) : (
                <GuestHouseLogoFull className={classes.logo} />
              )}
            </div>

            <div className={classes.toolbarSearch}>
              <TextField
                fullWidth
                autoFocus={isSearchPage}
                defaultValue={router.query.q?.toString()}
                className={cx({
                  [classes.searchField]: true,
                  [classes.searchFieldHide]: !md && isOpen,
                })}
                id="search"
                placeholder={searchPlaceholderText(router.location.pathname, userContext.roles)}
                variant="outlined"
                margin="dense"
                inputRef={searchInputRef}
                InputProps={{
                  className: classes.searchFieldInput,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconSearch className={classes.searchFieldIcon} />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>

            {userContext.user && (
            <div
              className={classes.profileMenuRegionContainer}
              id="profile-menu"
            >
              <Box
                display="flex"
                order={md ? 2 : 1}
              >
                <ProfileMenu />
              </Box>

              <Box
                order={md ? 1 : 2}
              >
                {userContext.activeRegion && (
                <Protected allowedRoles={['admin', 'design_manager', 'designer']}>
                  <Chip
                    size="small"
                    data-test="active-region-chip"
                    label={userContext.activeRegion.name}
                    style={{
                      maxWidth: md ? 'none' : 100,
                      marginRight: md ? theme.spacing(1) : 0
                    }}
                    onDelete={userContext.regions.length > 1 ? regionClear : null}
                  />
                </Protected>
                )}
              </Box>
            </div>
            )}
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        className={cx(classes.drawer, {
          [classes.drawerHidden]: isSingleHome,
        })}
        variant="persistent"
        anchor="left"
        open={isOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          {!lg && open && (
            <IconButton
              className={classes.drawerHeaderClose}
              size="large"
              onClick={handleDrawerClose}
            >
              <ChevronLeftIcon />
              Close
            </IconButton>
          )}

          <div className={classes.drawerLogo}>
            <GuestHouseLogoFull className={classes.drawerLogoSvg} />
          </div>
        </div>

        <div className={classes.navigation}>
          {
            !isSingleHome && Object.keys(routes)
              .filter(key => routes[key].nav === true)
              .sort((a, b) => routes[a].order - routes[b].order)
              .map(key => {
                const route = routes[key];
                const { Icon } = route;

                return (
                  <React.Fragment key={key}>
                    <Protected allowedRoles={route.roles || ['*']}>
                      <Flagged allowedFlags={route.flags || ['*']}>
                        <Link
                          className={cx(classes.navigationItem, {
                            [classes.navigationItemActive]: !!matchPath(router.location.pathname, route.path),
                          })}
                          to={route.path}
                          data-test={`nav-link-${key}`}
                        >
                          <div className={classes.navigationIcon}>
                            
                            <Icon className={classes.navIcon} />
                               
                          </div>

                          <div className={classes.navigationLabel}>
                            {route.title}
                          </div>
                        </Link>
                      </Flagged>
                    </Protected>
                  </React.Fragment>
                );
              })
          }
        </div>

      </Drawer>

      <main className={classes.content}>
        <div className={classes.contentToolbarSpacing} />

        {children}
      </main>
    </div>
  );
};

export default Backend;
