import makeStyles from '@ui/utils/makeStyles';
import { CSSObject } from 'tss-react';

export default makeStyles<
{},
'deleteButton'
>()((theme, _params, classes) => {
  const deleteButton = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    opacity: 0,
    right: theme.spacing(1),
    pointerEvents: 'none',
    transition: theme.transitions.create(['opacity'], { duration: 200 })
  } as CSSObject;

  return {
    timelineItem: {
      borderRadius: theme.shape.borderRadius,
      width: '100%',
      padding: theme.spacing(2),
      [`&:hover .${classes.deleteButton}`]: {
        opacity: 1,
        pointerEvents: 'all',
      }
    },
    deleteButton,
  };
});
